<script setup lang="ts">
import type {PropType} from "vue";
import type {WpMedia} from "~/integration/wordpress/types";
import {useWp} from "~/composables/Redmonkey";
import {NuxtLink} from "#components";

const {
  getMediaHeightFromMedia,
  getMediaWidthFromMedia,
  getMediaAltFromMedia,
  getMediaUrlFromMedia,
  isExternalLink
} = useWp();

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  image: {
    type: Object as PropType<WpMedia>
  },
  whiteLogo: {
    type: Object as PropType<WpMedia>
  },
  city: {
    type: String,
    default: ''
  },
  price: {
    type: String,
    default: ''
  },
  dateFrom: {
    type: String,
    default: ''
  },
  dateTo: {
    type: String,
    default: ''
  }
})

const LinkComponent = isExternalLink(props.url) ? 'a' : NuxtLink;

function getRandomString(length = 18) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const getDate = () => {
  if (!props.dateFrom) return false;
  const date = new Date(props.dateFrom);

  const day = date.getUTCDate();
  const monthNames = [
    "січня", "лютого", "березня", "квітня", "травня", "червня",
    "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}

const getTime = () => {
  if (!props.dateFrom || !props.dateTo) return false;

  const date1 = new Date(props.dateFrom);
  const date2 = new Date(props.dateTo);

  const hours1 = String(date1.getUTCHours()).padStart(2, '0');
  const minutes1 = String(date1.getUTCMinutes()).padStart(2, '0');

  const hours2 = String(date2.getUTCHours()).padStart(2, '0');
  const minutes2 = String(date2.getUTCMinutes()).padStart(2, '0');

  // Return the formatted time range
  return `${hours1}:${minutes1}-${hours2}:${minutes2}`;
};
</script>

<template>
  <div class="event-item">
    <component :is="url ? LinkComponent : 'div'" :to="url" :href="url">
      <div class="event-content flex wrap">
        <div class="figure figure-1"></div>
        <div class="figure figure-2"></div>
        <div class="figure figure-3"></div>
        <div class="figure figure-4"></div>
        <div class="event-content-side flex wrap">
          <div class="content-side-image">
            <picture v-if="getMediaUrlFromMedia(image)">
              <img
                  :src="getMediaUrlFromMedia(image)"
                  :alt="getMediaAltFromMedia(image)"
                  :width="getMediaWidthFromMedia(image)"
                  :height="getMediaHeightFromMedia(image)"
              />
            </picture>
            <div class="white-logo" v-if="getMediaUrlFromMedia(whiteLogo)">
              <img
                  :src="getMediaUrlFromMedia(whiteLogo)"
                  :alt="getMediaAltFromMedia(whiteLogo)"
                  :width="getMediaWidthFromMedia(whiteLogo)"
                  :height="getMediaHeightFromMedia(whiteLogo)"
              />
            </div>
            <h3 class="name" v-if="name">
              {{ name }}
            </h3>
          </div>
          <div class="content-side">
            <div class="figure figure-1"></div>
            <div class="figure figure-2"></div>
            <h4 v-if="title">{{ title }}</h4>
            <h5 v-if="subTitle">{{ subTitle }}</h5>
            <div class="location flex wrap justify">
              <div class="info-item" v-if="getDate()">
                <div class="name">Дата:</div>
                <div class="value">{{ getDate() }}</div>
              </div>
              <div class="info-item" v-if="city">
                <div class="name">Місто:</div>
                <div class="value">{{ city }}</div>
              </div>
              <div class="info-item" v-if="price">
                <div class="name">Ціна:</div>
                <div class="value">{{ price }}</div>
              </div>
            </div>
            <div class="time-action flex wrap">
              <div class="time info-item" v-if="getTime()">
                <div class="name">Час:</div>
                <div class="value">{{ getTime() }}</div>
              </div>
              <client-only>
                <div class="button" v-if="url">
                  <component class="action secondary" :is="LinkComponent" :to="url" :href="url">Записатись</component>
                </div>
              </client-only>
            </div>
          </div>
        </div>
        <div class="event-barcode-side">
          <div class="barcode-side">
            <p>Натисни на квиток щоб дізнатись більше</p>
            <img src="/images/barcode.png" :alt="name"/>
            <client-only>
              <p class="code">{{ getRandomString() }}</p>
            </client-only>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/event_item";
</style>